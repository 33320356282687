var BASE_URL1 = "https://www.tocoma.co/api";

// LANDING PAGE URLS
export const LANDING__ABOUT_URL = BASE_URL1 + "/About/getAbout";
export const LANDING__SERVICES_URL = BASE_URL1 + "/Service/service-category";
export const LANDING__CAROUSEL_URL = BASE_URL1 + "/Home/getHomeSlider";

// ABOUT PAGE URLS
export const ABOUT__URL = BASE_URL1 + "/About/getAboutpageInfo";

// SERVICE URLS
export const SERVICE__SURVICE_URL = BASE_URL1 + "/Service";
export const SERVICE__MAINCATEGORY_URL =
  BASE_URL1 + "/Service/service-category";
export const SERVICE__LIST_URL = BASE_URL1 + "/Service/service-all";

// PROJECT URLS
export const PROJECT__URL = BASE_URL1 + "/Project";
export const ALL_PROJECT__URL = BASE_URL1 + "/Project/getproject";
export const PROJECT_DETAILS_URL =
  BASE_URL1 + "/Project/getprojectDetails?projectslag=";

// CAREER URLS
export const CAREER_APPLY_URL = BASE_URL1 + "/Career";
export const CAREER_JOBLIST_URL = BASE_URL1 + "/Career/GetAllOpenJob";
export const CAREER_DETAILS_URL = BASE_URL1 + "/Career/GetJobDetails";

// CONTACT URLS
export const GENERAL_INQUERY_URL =
  BASE_URL1 + "/Contact/ApplyForGeneral-Inquery";
export const SUB_CONSTRUCTOR_URL =
  BASE_URL1 + "/Contact/ApplyForSubConstructor";
export const ENLISTED_SUPPLIER_URL =
  BASE_URL1 + "/Contact/ApplyForEnlisted-Supplier";
